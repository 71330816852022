<template>
  <div style="min-height: 80vh;"
  class="d-flex justify-center align-center login">
    <login-box
      @login="onLogin"
    />
  </div>

</template>

<script>
import LoginBox from "@/ee09/login/login-box";
export default {
  name: "login",
  components: {LoginBox},
  methods:{
    onLogin(){
      this.$router.push({name:"Home"});
      setTimeout(function (){
        window.$utils.navigation.reload()
      },200)
    }
  }

}
</script>
